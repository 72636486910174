@font-face {
  font-family: 'IBMPlexSerif';
  font-display: swap;
  font-weight: 400;
  src: url('/fonts/IBMPlexSerif-Text.woff2') format('woff2');
}
@font-face {
  font-family: 'IBMPlexSerif';
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  src: url('/fonts/IBMPlexSerif-TextItalic.woff2') format('woff2');
}

  /*Gillsans*/
  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans-BoldItalic.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans-Italic.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans-Bold.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans-UltraBold.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans-UltraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans-LightItalic.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans-Light.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/gillsans/GillSans-SemiBold.woff2') format('woff2'),
        url('/fonts/gillsans/GillSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  /*Gibbs*/
  @font-face {
    font-family: 'Gibbs Book';
    src: url('/fonts/gibbs/Gibbs-Bold.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  

  @font-face {
    font-family: 'Gibbs Book';
    src: url('/fonts/gibbs/Gibbs-Book.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  .gibbs-book {
    font-family: 'Gibbs Book';
  }

  @font-face {
    font-family: 'Gibbs';
    src: url('/fonts/gibbs/Gibbs-Light.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  .gibbs {
    font-family: 'Gibbs', sans-serif;
  }

  @font-face {
    font-family: 'Gibbs Book';
    src: url('/fonts/gibbs/Gibbs-BookItalic.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gibbs';
    src: url('/fonts/gibbs/Gibbs-Black.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gibbs Book';
    src: url('/fonts/gibbs/Gibbs-BoldItalic.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gibbs';
    src: url('/fonts/gibbs/Gibbs-BlackItalic.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gibbs';
    src: url('/fonts/gibbs/Gibbs-ThinItalic.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gibbs';
    src: url('/fonts/gibbs/Gibbs-LightItalic.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gibbs';
    src: url('/fonts/gibbs/Gibbs-MediumItalic.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gibbs';
    src: url('/fonts/gibbs/Gibbs-Medium.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gibbs';
    src: url('/fonts/gibbs/Gibbs-Thin.woff2') format('woff2'),
        url('/fonts/gibbs/Gibbs-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
